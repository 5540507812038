import logo from 'Images/logo_hole.png';

import './About.scss';

const About = () => {
    return (
      <div className="about">
        coming soon!
      </div>
    );
  };
  
export default About;