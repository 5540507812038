import logo from 'Images/logo_hole.png';

import './Work.scss';

const Work = () => {
    return (
      <div className="work">
        coming soon!
      </div>
    );
  };
  
export default Work;